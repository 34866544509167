<template>
    <v-container>
        <v-row>
            <v-col cols="6">
                <v-card flat>
                    <v-card-title>
                        <v-icon>mdi-file-chart-outline</v-icon>
                        <span>Månedsrapporter</span>
                    </v-card-title>
                    <v-card-text class="text--primary">
                        <p>Se oversikt over innleverte rapporter.</p>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn to="/sorg/rapporter/liste" outlined>Vis</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'ReportsPage',
};
</script>
